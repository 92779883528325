import { useSetAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';

import { ReactComponent as Talus } from 'src/assets/icons/talus.svg';
import { DeleteDialog } from 'src/components/dialogs/DeleteDialog/DeleteDialog';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { ExternalLink, Link } from 'src/elements/Link';
import { PreTitle } from 'src/elements/Text';
import { currentUserAtom } from 'src/utils/atoms/state';
import { useDialog } from 'src/utils/hooks/useDialog';

const Container = styled.footer`
  width: 100%;
  padding-top: 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkBorderDefault};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 1032px) {
    flex-direction: column;
  }
`;

interface Props {
  showDelete?: boolean;
}

export const Footer = ({ showDelete = false }: Props) => {
  const showDeleteDialog = useDialog(DeleteDialog);
  const setCurrentUser = useSetAtom(currentUserAtom);
  const navigate = useNavigate();

  return (
    <Container>
      <FlexGroup $gap={8} style={{ flexWrap: 'wrap' }}>
        <Link to="/privacy-policy">
          <Button $variant="text">Privacy Policy</Button>
        </Link>

        <Link to="/terms-of-service">
          <Button $variant="text">Terms of Service</Button>
        </Link>

        <Link to="/cookies">
          <Button $variant="text">Cookies</Button>
        </Link>

        {showDelete && (
          <Fragment>
            <Button onClick={() => showDeleteDialog({})} $variant="text">
              Delete my Bae
            </Button>

            <Button
              onClick={() => {
                setCurrentUser({ soulmateId: '' });
                navigate('/');
              }}
              $variant="text"
            >
              Logout
            </Button>
          </Fragment>
        )}
      </FlexGroup>

      <FlexGroup $flex={0}>
        <ExternalLink $clear href="https://talus.network" style={{ whiteSpace: 'nowrap' }}>
          <PreTitle $size="bodySmall" $color="darkSecondary">
            POWERED BY&nbsp;
          </PreTitle>

          <Talus style={{ height: 10 }} />
        </ExternalLink>
      </FlexGroup>
    </Container>
  );
};
