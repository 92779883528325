import { animated, useSpring, useTransition } from '@react-spring/web';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { useFadeInAndSlideSpring } from 'src/assets/springs';
import { SettingsDialog } from 'src/components/dialogs/SettingsDialog/SettingsDialog';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Icon } from 'src/elements/Icon';
import { Svg } from 'src/elements/Svg';
import { Text } from 'src/elements/Text';
import { tikTokTokensAtom } from 'src/utils/atoms/socialCredentials';
import { useDialog } from 'src/utils/hooks/useDialog';
import { useTikTokAuth } from 'src/utils/hooks/useTikTokAuth';
import { useViewport } from 'src/utils/hooks/useViewport';

export const TikTokLogin = () => {
  const [showDoMagic, setShowDoMagic] = useState(false);
  const [flowError, setFlowError] = useState('');
  const showSettingsDialog = useDialog(SettingsDialog);
  const { loading, error, data, handleAuthClick } = useTikTokAuth();
  const setTikTokTokens = useSetAtom(tikTokTokensAtom);
  const { isMobile } = useViewport();

  const fadeAnimation = useFadeInAndSlideSpring({ delay: 110 });

  const widthAnimation = useSpring({
    width: data ? '77px' : '242px',
    config: { tension: 210, friction: 20 },
    onRest: () => setShowDoMagic(true),
  });

  const transition = useTransition(showDoMagic, {
    from: { opacity: 0, transform: 'translateY(-4px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-4px)' },
    config: { tension: 210, friction: 20 },
  });

  useEffect(() => {
    if (data) {
      setTikTokTokens(data);
    }
  }, [data, setTikTokTokens]);

  const handleError = ({ message }: { message: string }) => {
    setFlowError(message);
  };

  return (
    <FlexGroup $vertical $gap={8} $flex={0}>
      <FlexGroup $gap={32} $start style={{ justifyContent: isMobile ? 'center' : 'flex-start' }}>
        <FlexGroup $gap={8} $flex={0}>
          <Button
            as={animated.button}
            $loading={loading}
            $size="large"
            disabled={!!data}
            style={{ ...widthAnimation, ...fadeAnimation }}
            onClick={handleAuthClick}
          >
            <Svg $size="bodyLarge" name="tiktok" />

            {!data && 'Connect with TikTok'}
          </Button>

          {data && <Icon name="check" $color="success" $size="bodyLarge" label="TikTok connected" />}
        </FlexGroup>

        {transition(
          (style, item) =>
            item &&
            data && (
              <Button
                as={animated.button}
                $size="large"
                style={style}
                onClick={() => showSettingsDialog({ onError: handleError })}
              >
                Create AI Bae
              </Button>
            )
        )}
      </FlexGroup>

      {(error || flowError) && (
        <Text $color="error" role="alert" style={{ marginLeft: 16 }}>
          {flowError || 'There was an issue authenticating, please try again later'}
        </Text>
      )}
    </FlexGroup>
  );
};
