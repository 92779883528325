import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Radio, RadioGroup } from 'src/components/ui/Radio';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { PreTitle } from 'src/elements/Text';

const schema = z.object({
  soulmateGender: z.string(),
});

export type PreferencesFormFields = z.infer<typeof schema>;

interface Props {
  onSubmit: (values: PreferencesFormFields) => void;
  buttonLabel: string;
  buttonDisabled?: boolean;
}

export const PreferencesForm = ({ onSubmit, buttonLabel, buttonDisabled = false }: Props) => {
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<PreferencesFormFields>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: { soulmateGender: 'female' },
  });

  return (
    <FlexGroup as="form" $vertical $gap={16} onSubmit={handleSubmit(onSubmit)}>
      <PreTitle $color="darkPrimary" $size="bodyLarge">
        SOULMATE GENDER
      </PreTitle>

      <RadioGroup value={watch('soulmateGender')} onValueChange={(value) => setValue('soulmateGender', value)}>
        <Radio small label="Female" value="female" />
        <Radio small label="Male" value="male" />
      </RadioGroup>

      <div style={{ margin: '0 -28px -28px -28px', width: 'calc(100% + 56px)' }}>
        <Button
          type="submit"
          disabled={buttonDisabled}
          $size="large"
          $variant="primary"
          $fullwidth
          $loading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </div>
    </FlexGroup>
  );
};
