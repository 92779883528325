import * as RadixRadio from '@radix-ui/react-radio-group';
import { ReactNode } from 'react';
import { css, styled } from 'styled-components';

import { Text } from 'src/elements/Text';

const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Item = styled(RadixRadio.Item)<{ $small: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.darkBorderDefault};
  background: ${({ theme }) => theme.colors.bgLight};

  ${({ $small }) => {
    if ($small) {
      return css`
        width: 16px;
        height: 16px;
      `;
    }
  }}

  &[disabled] {
    border-color: ${({ theme }) => theme.colors.lightBorderSubtle};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.secondary};
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

const Indicator = styled(RadixRadio.Indicator)<{ $small: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.secondary};

    ${({ $small }) => {
      if ($small) {
        return css`
          width: 8px;
          height: 8px;
        `;
      }
    }}
  }
`;

interface Props {
  value: string;
  label: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
  small?: boolean;
}

export const Radio = ({ value, label, description, disabled = false, small = false }: Props) => {
  return (
    <Container>
      <Item value={value} id={value} disabled={disabled} $small={small}>
        <Indicator $small={small} />
      </Item>

      <Label htmlFor={value}>
        <Text $color="darkPrimary" $size={small ? 'bodyMedium' : 'bodyLarge'}>
          {label}
        </Text>

        {description && <Text $color="darkSecondary">{description}</Text>}
      </Label>
    </Container>
  );
};

export const RadioGroup = styled(RadixRadio.Root)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;
