import * as amplitude from '@amplitude/analytics-browser';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { queryKeys } from 'src/api/queryKeys';
import { useCreateSoulmateMutation } from 'src/api/soulmate/query';
import { Dialog, DialogContent } from 'src/components/ui/Dialog';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Separator } from 'src/elements/Separator';
import { Text } from 'src/elements/Text';
import { spotifyTokensAtom, tikTokTokensAtom } from 'src/utils/atoms/socialCredentials';
import { currentUserAtom } from 'src/utils/atoms/state';
import { useHideDialog } from 'src/utils/hooks/useDialog';

import { PreferencesForm, PreferencesFormFields } from '../SettingsDialog/PreferencesForm';
import { SpotifyLogin } from './SpotifyLogin';
import { TikTokLogin } from './TikTokLogin';

export const ConnectSocialsDialog = () => {
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const hide = useHideDialog();
  const queryClient = useQueryClient();

  const setCurrentUser = useSetAtom(currentUserAtom);
  const [tikTokTokens, setTikTokTokens] = useAtom(tikTokTokensAtom);
  const [spotifyTokens, setSpotifyTokens] = useAtom(spotifyTokensAtom);

  const { mutateAsync: createSoulmate } = useCreateSoulmateMutation();

  const handleFinalize = async ({ soulmateGender }: PreferencesFormFields) => {
    try {
      if (!tikTokTokens) {
        throw new Error();
      }

      const { data } = await createSoulmate({
        tikTok: tikTokTokens,
        spotify: spotifyTokens,
        regenerate: true,
        userPreferences: {
          soulmateGender,
        },
      });

      amplitude.setUserId(data.soulmateId);

      setCurrentUser({ soulmateId: data.soulmateId });

      await queryClient.invalidateQueries({ queryKey: queryKeys.soulmateProgress({ soulmateId: data.soulmateId }) });
      queryClient.removeQueries({ queryKey: queryKeys.soulmate({ soulmateId: data.soulmateId }) });

      setTikTokTokens(undefined);
      setSpotifyTokens(undefined);

      hide();
      navigate(`/@${data.soulmateId}`);
    } catch {
      hide();

      setError('There was an issue generating a soulmate, please try again later');
    }
  };

  return (
    <Dialog>
      {() => (
        <DialogContent
          title="BEAUTIFY YOUR BAE"
          description="Connect other social media account to enhance your AI Bae's personality."
        >
          <FlexGroup $vertical $gap={8}>
            <Separator />

            <TikTokLogin />

            <Separator />

            <SpotifyLogin />

            <Separator style={{ marginBottom: 12 }} />

            <PreferencesForm
              onSubmit={handleFinalize}
              buttonLabel="BEAUTIFY MY BAE"
              buttonDisabled={!spotifyTokens || !tikTokTokens}
            />

            {error && (
              <Text $color="error" $size="bodySmall">
                {error}
              </Text>
            )}
          </FlexGroup>
        </DialogContent>
      )}
    </Dialog>
  );
};
