import * as amplitude from '@amplitude/analytics-browser';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { queryKeys } from 'src/api/queryKeys';
import { useCreateSoulmateMutation } from 'src/api/soulmate/query';
import { Dialog, DialogContent } from 'src/components/ui/Dialog';
import { tikTokTokensAtom } from 'src/utils/atoms/socialCredentials';
import { currentUserAtom } from 'src/utils/atoms/state';
import { useHideDialog } from 'src/utils/hooks/useDialog';

import { PreferencesForm, PreferencesFormFields } from './PreferencesForm';

interface Props {
  onError: (error: { message: string }) => void;
}

export const SettingsDialog = ({ onError }: Props) => {
  const navigate = useNavigate();
  const hide = useHideDialog();
  const queryClient = useQueryClient();

  const setCurrentUser = useSetAtom(currentUserAtom);
  const [tikTokTokens, setTikTokTokens] = useAtom(tikTokTokensAtom);

  const { mutateAsync: createSoulmate } = useCreateSoulmateMutation();

  const handleFinalize = async ({ soulmateGender }: PreferencesFormFields) => {
    try {
      if (!tikTokTokens) {
        throw new Error();
      }

      const { data } = await createSoulmate({
        tikTok: tikTokTokens,
        userPreferences: {
          soulmateGender,
        },
      });

      amplitude.setUserId(data.soulmateId);

      setCurrentUser({ soulmateId: data.soulmateId });

      await queryClient.invalidateQueries({ queryKey: queryKeys.soulmateProgress({ soulmateId: data.soulmateId }) });
      queryClient.removeQueries({ queryKey: queryKeys.soulmate({ soulmateId: data.soulmateId }) });

      setTikTokTokens(undefined);

      hide();
      navigate(`/@${data.soulmateId}`);
    } catch {
      hide();

      onError({ message: 'There was an issue generating a soulmate, please try again later' });
    }
  };

  return (
    <Dialog>
      {() => (
        <DialogContent title="PREFERENCES" description="Make your AI Bae unique by customizing its settings.">
          <PreferencesForm onSubmit={handleFinalize} buttonLabel="CREATE AI BAE" />
        </DialogContent>
      )}
    </Dialog>
  );
};
